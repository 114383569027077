import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/shared/layout";
import { Seo } from "../../components/shared/seo";
import { ProjectGridItem } from "../../components/shared/projectGridItem";
import { gridProjectStyle } from "../../utils/gridStyles";
import { ProjectsNavbar } from "../../components/projects/projectsNavbar";

const ProjectsPage = (props) => {
  // --->  Data <--- //
  const projects = props.data.allStrapiProject.nodes;
  // FILTER
  // Filtered DATA
  const [dataFiltered, setDataFiltered] = React.useState(projects);
  // SELECTED FILTER TYPE
  const [filterSelected, setFilterSelected] =
    React.useState("All Project Types");

  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
      bodyClass={"preimeryBg"}
    >
      {/* ----- Projects Navbar ----- */}
      <ProjectsNavbar
        projects={projects}
        setDataFiltered={setDataFiltered}
        setFilterSelected={setFilterSelected}
      />
      {/* ----- Projects Grid ----- */}
      <div className={`${gridProjectStyle}`}>
        {dataFiltered.map((project, index) => {
          return (
            <ProjectGridItem key={index} project={project} index={index} />
          );
        })}
      </div>
    </Layout>
  );
};
export const query = graphql`
  query ProjectsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiProject(
      sort: { fields: createdAt, order: DESC }
      filter: { title: { regex: "/^((?!DO NOT DELETE - ).)*$/" } }
    ) {
      nodes {
        title
        year
        projectType {
          projectType
        }
        heroImage {
          imageFile {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: NO_CHANGE
                  aspectRatio: 1.777777777777778
                  #width: 900
                  height: 300
                  quality: 100
                  #layout: FULL_WIDTH
                  transformOptions: { fit: INSIDE }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default ProjectsPage;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Projects"}
    description={"IlCollection Artists"}
    keywords={"IlCollection Artists, Art"}
  />
);
