import React from "react";
import { Link } from "gatsby";
import { CloseIcon, FilterIcon } from "../../images/icons";
import { GetFilterItems } from "../../utils/filterData";
import { LinkIcon } from "../shared/links";
import { motion, AnimatePresence } from "framer-motion";
import { handleType } from "../../utils/filterHandler";
import {
  responsiveTextBasic,
  responsiveIconsBasic,
  layoutFilterHeight,
  stickyNav,
} from "../shared/styles";

export const ProjectsNavbar = ({
  projects,
  setDataFiltered,
  setFilterSelected,
}) => {
  const [isFilter, setIsFilter] = React.useState(false);
  const handleFilter = () => {
    setIsFilter(!isFilter);
  };
  // FILTER ------ //
  const [allProjects, setAllProjects] = React.useState(projects);
  const projectsData = GetFilterItems(projects, "Project Types");

  return (
    <div
      className={`relative ${layoutFilterHeight} mb-xl ${stickyNav} bg-yellow-50 `}
    >
      <AnimatePresence initial={false}>
        {!isFilter ? (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="absolute w-full flex justify-between md:items-start xs:items-center"
          >
            {/* <Link to={`/projects`}>
              <LinkIcon classes={responsiveTextBasic}>All Projects</LinkIcon>
            </Link> */}
            <div className={`opacity-50 ${responsiveTextBasic}`}>
              All Projects
            </div>

            <button onClick={() => handleFilter()}>
              <LinkIcon>
                <div className={`flex items-center ${responsiveTextBasic}`}>
                  <div className="text-sm mr-5">Filter</div>
                  <FilterIcon classes={responsiveIconsBasic} />
                </div>
              </LinkIcon>
            </button>
          </motion.div>
        ) : (
          /*FILTER */
          <motion.div
            key={isFilter}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute w-full z-[3] bg-yellow-50 pb-md "
          >
            {/* --- CLOSE */}
            <div className="flex justify-between items-start gap-x-sm ">
              <div className="order-last ">
                <button
                  onClick={() => handleFilter()}
                  className={"flex items-start"}
                >
                  <LinkIcon classes={"h-fit"}>
                    <CloseIcon classes={"w-[1rem] h-auto "} />
                  </LinkIcon>
                </button>
              </div>
              <div className="order-first grow">
                {/* ProjectType */}
                {projectsData.map((project, index) => {
                  return (
                    <button
                      key={index}
                      type="button"
                      onClick={() =>
                        handleType(
                          project,
                          allProjects,
                          "Project Types",
                          setDataFiltered,
                          setFilterSelected
                        )
                      }
                      className={`${responsiveTextBasic} block`}
                    >
                      <LinkIcon>{project}</LinkIcon>
                    </button>
                  );
                })}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
